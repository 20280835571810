@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

::-webkit-scrollbar {
    width: 7px;
    background: #031A30;
   
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
   
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #E0A040;
    border-radius: 10px;
    height: 5px;
    
}


#mybtn{
    
}



@keyframes borderAnimation {
    30% {
      border-width: 2px 0 0 0;
      border-color: orange transparent transparent transparent;
    }

    
  }
  
  .border-animated {
    animation: borderAnimation 4s infinite linear;
    border-style: solid;
    border-width: 2px;
    border-color: transparent; /* Initially make border transparent */
    padding: 1rem;
    border-radius: 10px;
    position: relative;

  }
  
  